.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999;
}

.top-right {
    top: 12px;
    right: 12px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
}

.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
}

.top-left {
    top: 12px;
    left: 12px;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
}

.bottom-left {
    bottom: 12px;
    left: 12px;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
}

.notification {
    background: #fff;
    transition: 0.3s ease;
    /* position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 30px;
    margin-bottom: 15px;
    width: 300px;
    max-height: 100px;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 10px #999; */
    /* color: #000; */
    /* opacity: 0.9; */
    /* background-position: 15px; */
    background-repeat: no-repeat;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    position: absolute;
    right: 16px;
    bottom: 16.5px;
    border-radius: 16px;
}

.notification:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
    cursor: pointer;
}

.notification-icon-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    width: 76px;
    height: 76px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 80px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.notification-icon-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 44px;
    height: 44px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.notification-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 196px;
    /* height: 41px; */
}

.notification-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #fffffe;
    margin: 0px;
}

.notification-message {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #fffffe;
    margin: 0px;
    letter-spacing: 0.02em;
}

.notification-image {
    float: left;
    margin-right: 15px;
}

.notification-image img {
    width: 30px;
    height: 30px;
}

.toast {
    width: 320px;
    height: 92px;
    color: #fff;
    /* padding: 20px 15px 10px 10px; */
    padding: 8px 16px;
}

/* .notification-container button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-weight: 700;
    color: #fff;
    outline: none;
    border: none;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.8;
    line-height: 1;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
} */

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}
