* {
    margin: 0;
    padding: 0;
    /* display: block; */
}

 @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap"); 

/* .css-1fdsijx-ValueContainer {
    font-size: 12px;
}

.css-13cymwt-control {
    border: none;
}

.css-13cymwt-control:focus-within {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 0px 2px #dbf2fb, inset 0px 0px 0px 1px #77cbed;
    border-radius: 5px;
} */
/*
@font-face {
    font-family: 'Fellix';
    src: local('Fellix-Black'), url('../fonts/Fellix-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Fellix-Regular';
    src: local('Fellix-Regular'), url('../fonts/Fellix-Regular.ttf') format('truetype');
} */

body {
    font-family: 'Fellix-Regular', Montserrat !important;
    font-display: swap;
}

:root {
    --primary-font: 'Fellix-Regular';
}
